import React from 'react';

const GoogleTagManager = () => {


    return (
        <>
            {/* Google Tag Manager (noscript) */}
            <noscript
                dangerouslySetInnerHTML={{__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLL3H95" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`}}/>
            {/* End Google Tag Manager (noscript) */}
        </>
    )
}

export default GoogleTagManager;
