import Head from 'next/head'
import React from 'react'

import Footer from '@/components/Footer';
import GoogleTagManager from '@/components/GoogleTagManager';
import Header from '@/components/Header';
import {ToastContainer} from 'react-toastify';

export default function Layout({ children, ogData }) {

    return (
        <>
            {ogData ?
                <Head>
                    <meta charSet="utf-8"/>
                    <link rel="shortcut icon" href="/TTFavicon.ico"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                    {/* Open Graph */}
                    <meta property="og:url" content={ogData.currentURL} key="ogurl"/>
                    <meta property="og:image" content={ogData.previewImage} key="ogimage"/>
                    <meta property="og:title" content={ogData.pageTitle} key="ogtitle"/>
                    {ogData.pageDescription ?
                        <meta property="og:description" content={ogData.pageDescription} key="ogdescription"/> : ""
                    }
                    <title>Torah Table</title>

                </Head>
                :
                <Head>
                    <meta charSet="utf-8"/>
                    <link rel="shortcut icon" href="/TTFavicon.ico"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                    <meta property="og:image" content="/images/link-image.png" key="ogimage"/>
                    <title>Torah Table</title>

                </Head>
            }

            <GoogleTagManager />

            <Header />

            <main>{children}</main>

            <ToastContainer />
            <Footer/>
        </>
    )
}
